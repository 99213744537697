<template>
    <div
        style="height:100%;"
        @click="$store.commit('update_little_menu', false)"
    >
        <!-- # 같이보기 -->
        <div
            v-if="$store.state.main_tab == '계산' && $store.state.twin"
            :style="{ height: computedMaxHeight + 'px' }"
            style="overflow-y:auto;"
            class="rotate primary"
        >
            <!-- # 한줄 보기 -->
            <v-sheet
                v-if="$store.state.line == 'single'"
                class="mx-auto pa-4 mt-2 pb-10 rounded-10"
                min-height="180"
                color="transparent"
            >
                <!-- # 계산식 -->
                <div
                    class="pa-1 d-flex justify-end flex-wrap"
                    style="text-align:end; min-height:60px;"
                >
                    <!-- # 계산식 배열 -->
                    <div
                        v-for="(item, index) in list" :key="index"
                        class="font-weight-medium d-flex justify-end"
                    >
                        <!-- # 연산자 -->
                        <div
                            class="mt-3px mx-1"
                        >
                            <!-- # 연산자 기본모드 -->
                            <div
                                class="text-h6 font-weight-bold white--text"
                            >
                                {{$pretty_operator(item.operator)}}
                            </div>
                        </div>

                        <!-- # 숫자 기본모드 -->
                        <div
                            style="z-index:40;"
                        >
                            <!-- # 메인 계산식 -->
                            <p
                                class="mb-0 text-h5 white--text"
                            >
                                {{$formula_main(item.number, item.scale, $store.state.sub_tab)}}
                            </p>

                            <!-- # 서브 계산식 -->
                            <p
                                class="mb-0 white--text"
                                style="margin-top:-2px; font-size:16px; font-weight:400;"
                            >
                                {{$formula_sub(item.number, item.scale, $store.state.sub_tab, $store.state.input_tab)}}
                            </p>
                        </div>
                    </div>
                </div>

                <!-- # 결과값 -->
                <div
                    class="text-end font-weight-bold pa-1 mt-4 text-white"
                    style="color:white; height:32px;"
                >
                    <!-- # 결과값 메인줄 -->
                    <div v-if="!list.some(item => item.edit)" class="font-weight-bold text-h5 white--text">
                        {{computed_result.main}}
                    </div>

                    <!-- # 결과값 서브줄 -->
                    <div v-if="!list.some(item => item.edit)" class="text-h6 white--text">
                        {{computed_result.sub}}
                    </div>
                </div>
            </v-sheet>
        </div>

        <!-- 같이보기 -->
        <HomeHeader
            v-if="$store.state.twin"
        />

        <!-- 통화선택에 result 보내는 용도 -->
        <div v-show="false">
            {{$result_main(list, '000')}}
        </div>

        <!-- # 표시 화면 -->
        <div
            class="pa-2 pt-0"
            style="max-height:calc(100% - 160px);"
            @click="$edit_end(list); check_operator_edit_mode_end();"
        >
            <!-- # 메인탭 : 계산 -->
            <div
                v-if="$store.state.main_tab == '계산'"
                :style="{ height: computedMaxHeight + 'px' }"
                class="scroll-container mt-2"
                style="overflow-y:auto;"
            >
                <!-- # 한줄 보기 -->
                <v-sheet
                    v-if="$store.state.line == 'single'"
                    class="mx-auto pa-4 rounded-10 d-flex flex-column align-start"
                    :style="{ minHeight: computedMaxHeight2 + 'px' }"
                    style="width:100%;"
                >
                    <!-- # 계산식 -->
                    <div
                        class="pa-1 d-flex justify-end flex-wrap"
                        style="text-align:end; min-height:60px; width:100%;"
                    >
                        <!-- # 계산식 배열 -->
                        <div
                            v-for="(item, index) in list" :key="index"
                            class="font-weight-medium d-flex justify-end"
                        >
                            <!-- # 연산자 -->
                            <div
                                class="mt-3px mx-1"
                            >
                                <!-- # 연산자 기본모드 -->
                                <div
                                    v-show="!item.edit2"
                                    @click.stop="$edit_start2(list, item, index); focusStart('operator'+index)"
                                    class="text-h6 font-weight-bold operator--text"
                                >
                                    {{$pretty_operator(item.operator)}}
                                </div>

                                <!-- # 연산자 수정모드 -->
                                <div
                                    v-show="item.edit2"
                                    @click.stop=""
                                    style="position:relative; margin-bottom:-11px;"
                                >
                                    <v-text-field
                                        v-model="item.edit_operator"
                                        class="edit_mode2"
                                        :class="{ pc_chromium: $check_pc_chromium_browser() }"
                                        :style="{
                                            minWidth: item.edit_operator == '(' || item.edit_operator == ')' ? '0px' : '20px',
                                            width: $dynamicWidth2(item),
                                            paddingLeft: item.edit_operator? '0px':'19px'
                                        }"
                                        solo
                                        flat
                                        dense
                                        hide-details
                                        inputmode="none"
                                        :ref="'operator'+index"
                                        @focus="setActiveInput('operator'+index, 'operator', index)"
                                        @input="handleInput(item, index)"
                                        @keydown="handleKeydown_operator"
                                    ></v-text-field>
                                </div>
                            </div>

                            <!-- # 숫자 기본모드 -->
                            <div
                                v-show="!item.edit"
                                @click.stop="$edit_end(list); $edit_start(list, item, index); focusStart('number'+index)"
                                style="z-index:40;"
                            >
                                <!-- # 메인 계산식 -->
                                <p
                                    class="mb-0 text-h5 alter_black--text"
                                >
                                    {{$formula_main(item.number, item.scale, $store.state.sub_tab)}}
                                </p>

                                <!-- # 서브 계산식 -->
                                <p
                                    class="mb-0 alter_grey--text"
                                    style="margin-top:-2px; font-size:16px; font-weight:400;"
                                >
                                    {{$formula_sub(item.number, item.scale, $store.state.sub_tab, $store.state.input_tab)}}
                                </p>
                            </div>

                            <!-- # 숫자 수정모드 -->
                            <div
                                v-show="item.edit"
                                @click.stop=""
                                style="position:relative;"
                                :style="
                                    $store.state.sub_tab == '000' && $check_pc_chromium_browser() ? 'margin-bottom:12px;'
                                    : $store.state.sub_tab == '000' ? 'margin-bottom:11px;' : 'margin-bottom:-11px;'
                                "
                            >
                                <v-text-field
                                    v-model="item.edit_number"
                                    class="edit_mode"
                                    :class="{ pc_chromium: $check_pc_chromium_browser() }"
                                    style="min-width:13px; margin: 0; padding: 0; line-height: 1rem;" 
                                    :style="{
                                        width: $dynamicWidth(item),
                                        paddingLeft: item.edit_number? '0px':'12px',
                                    }"
                                    solo
                                    flat
                                    dense
                                    hide-details
                                    inputmode="none"
                                    :ref="'number'+index"
                                    @focus="setActiveInput('number'+index, 'number', index)"
                                    @input="$checkDisableKey(list)"
                                    @keydown="$edit_number_keyboard_allow_key"
                                ></v-text-field>
                            </div>
                        </div>

                        <!-- # 없을때 -->
                        <div
                            :class="{ 'cursor-blink': blink_cursor }"
                            :style="{ display: !blink_cursor ? 'none' : 'block' }"
                            style="margin-left:-1px; width:1px; height:29px; background:#374151; margin-top:1px;"
                        ></div>
                    </div>

                    <!-- 중간 공간 확보 -->
                    <div class="flex-grow-1"></div>

                    <!-- # 결과값 -->
                    <div
                        class="text-end font-weight-bold pa-1 mt-4 text-h5"
                        style="color:#0369a1; width:100%;"
                    >
                        <!-- # 결과값 메인줄 -->
                        <div
                            v-if="!list.some(item => item.edit)"
                            :class="computed_result.main == '유효하지 않은 값' || computed_result.main == 'Invalid value' ? 'font-weight-regular grey--text': 'font-weight-bold'"
                        >
                            <span v-if="$store.state.sub_tab != '000' && $store.state.summary_amount != 100 && computed_result.main != '유효하지 않은 값' && computed_result.main != 'Invalid value' && computed_result.main != '0' && computed_result.main != null">
                                ≈
                            </span>
                            {{computed_result.main}}
                        </div>

                        <!-- # 결과값 서브줄 -->
                        <div
                            v-if="!list.some(item => item.edit) && computed_result.main != computed_result.sub"
                            style="font-size:16px; font-weight:400;"
                        >
                            <span v-if="$store.state.sub_tab == '000' && $store.state.summary_amount != 100 && computed_result.sub != '유효하지 않은 값' && computed_result.sub != 'Invalid value' && computed_result.sub != '0' && computed_result.sub != null">
                                ≈
                            </span>
                            {{computed_result.sub}}
                        </div>
                    </div>
                </v-sheet>
            </div>















            <!-- # 메인탭 : 환전 -->
            <div
                v-if="$store.state.main_tab == '환전'"
                :style="{ height: computedMaxHeight + 'px' }"
                class="scroll-container mt-5px pb-2"
                style="overflow-y:auto;"
            >
                <div
                    class="pt-1"
                >
                    <draggable
                        v-if="exchange_list.length"
                        v-model="exchange_list"
                        ghost-class="ghost"
                        @choose="onChoose"
                        @unchoose="onUnchoose"
                        :delay="150"
                    >
                        <!-- # 환전 통화 목록 -->
                        <v-sheet
                            v-for="(item, index) in exchange_list" :key="index"
                            class="rounded-12 pt-3 pb-3 mb-3 px-4 mx-1"
                            style="box-shadow:0px 0px 4px 2px #e4e4e4; position:relative;"
                            :class="item.is_expanded? 'gradient-background':''"
                            :style="item.is_expanded? 'min-height:124px;':'height:78px;'"
                        >
                            <!-- # 환전 통화 -->
                            <div
                                class="ml-1"
                                style="padding-right:24px; padding-left:12px;"
                            >
                                <!-- # 계산식 -->
                                <div
                                    v-if="item.is_expanded"
                                    class="pa-1 pr-2 d-flex justify-end flex-wrap"
                                    style="text-align:end; min-height:60px;"
                                >
                                    <!-- # 계산식 배열 -->
                                    <div
                                        v-for="(item, index) in list" :key="index"
                                        class="font-weight-medium d-flex justify-end"
                                    >
                                        <!-- # 연산자 -->
                                        <div
                                            class="mt-3px mx-1"
                                        >
                                            <!-- # 연산자 기본모드 -->
                                            <div
                                                v-show="!item.edit2"
                                                @click.stop="$edit_start2(list, item, index); focusStart('operator'+index)"
                                                class="text-h6 font-weight-bold operator--text"
                                            >
                                                {{$pretty_operator(item.operator)}}
                                            </div>

                                            <!-- # 연산자 수정모드 -->
                                            <div
                                                v-show="item.edit2"
                                                @click.stop=""
                                                style="position:relative;"
                                                :style="
                                                   $check_pc_chromium_browser() ? 'margin-bottom:-10px;' : 'margin-bottom:-11px;'
                                                "
                                            >
                                                <v-text-field
                                                    v-model="item.edit_operator"
                                                    class="edit_mode2"
                                                    :class="{ pc_chromium: $check_pc_chromium_browser() }"
                                                    :style="{
                                                        minWidth: item.edit_operator == '(' || item.edit_operator == ')' ? '0px' : '20px',
                                                        width: $dynamicWidth2(item),
                                                        paddingLeft: item.edit_operator? '0px':'19px'
                                                    }"
                                                    solo
                                                    flat
                                                    dense
                                                    hide-details
                                                    inputmode="none"
                                                    :ref="'operator'+index"
                                                    @focus="setActiveInput('operator'+index, 'operator', index)"
                                                    @input="handleInput(item, index)"
                                                    @keydown="handleKeydown_operator"
                                                ></v-text-field>
                                            </div>
                                        </div>

                                        <!-- # 숫자 기본모드 -->
                                        <div
                                            v-show="!item.edit"
                                            @click.stop="$edit_end(list); $edit_start(list, item, index); focusStart('number'+index)"
                                            style="z-index:40;"
                                        >
                                            <!-- # 메인 계산식 -->
                                            <p
                                                class="mb-0 text-h5 alter_black--text"
                                            >
                                                {{$formula_main(item.number, item.scale, $store.state.sub_tab)}}
                                            </p>

                                            <!-- # 서브 계산식 -->
                                            <p
                                                class="mb-0 alter_grey--text"
                                                style="margin-top:-2px; font-size:16px; font-weight:400;"
                                            >
                                                {{$formula_sub(item.number, item.scale, $store.state.sub_tab, $store.state.input_tab)}}
                                            </p>
                                        </div>

                                        <!-- # 숫자 수정모드 -->
                                        <div
                                            v-show="item.edit"
                                            @click.stop=""
                                            style="position:relative;"
                                            :style="
                                                $store.state.sub_tab == '000' && $check_pc_chromium_browser() ? 'margin-bottom:12px;'
                                                : $store.state.sub_tab == '000' ? 'margin-bottom:11px;' : 'margin-bottom:-11px;'
                                            "
                                        >
                                            <v-text-field
                                                v-model="item.edit_number"
                                                class="edit_mode"
                                                :class="{ pc_chromium: $check_pc_chromium_browser() }"
                                                style="min-width:13px;"
                                                :style="{
                                                    width: $dynamicWidth(item),
                                                    paddingLeft: item.edit_number? '0px':'12px'
                                                }"
                                                solo
                                                flat
                                                dense
                                                hide-details
                                                inputmode="none"
                                                :ref="'number'+index"
                                                @focus="setActiveInput('number'+index, 'number', index)"
                                                @input="$checkDisableKey(list)"
                                                @keydown="$edit_number_keyboard_allow_key"
                                            ></v-text-field>
                                        </div>
                                    </div>

                                    <!-- # 없을때 -->
                                    <div
                                        :class="{ 'cursor-blink': blink_cursor }"
                                        :style="{ display: !blink_cursor ? 'none' : 'block' }"
                                        style="margin-left:-1px; width:1px; height:29px; background:#374151; margin-top:1px;"
                                    ></div>
                                </div>
                            </div>

                            <!-- # 환전 기본줄 -->
                            <div
                                class="d-flex"
                                style="width:100%;"
                            >
                                <!-- # 아이콘 (좌측) -->
                                <div>
                                    <!-- # 펼치기 -->
                                    <v-btn
                                        v-if="!item.is_expanded"
                                        icon
                                        x-small
                                        depressed
                                        style="position:absolute; top:4px; left:4px; z-index:3;"
                                        @click.stop="$selected_expand(item, exchange_list)"
                                    >
                                        <v-img
                                            width="20"
                                            height="20"
                                            contain
                                            src="@/assets/close_expand.svg"
                                        ></v-img>
                                    </v-btn>

                                    <!-- # 접기 -->
                                    <v-btn
                                        v-if="item.is_expanded"
                                        icon
                                        x-small
                                        depressed
                                        style="position:absolute; top:4px; left:4px; z-index:3;"
                                        @click.stop="item.is_expanded = false"
                                    >
                                        <v-img
                                            width="20"
                                            height="20"
                                            contain
                                            src="@/assets/open_expand.svg"
                                        ></v-img>
                                    </v-btn>

                                    <!-- # 삭제 -->
                                    <v-btn
                                        v-if="index != 0"
                                        icon
                                        x-small
                                        depressed
                                        style="position:absolute; bottom:4px; left:4px; z-index:3;"
                                        @click.stop="exchange_list.splice(index, 1)"
                                    >
                                        <v-img
                                            width="20"
                                            height="20"
                                            contain
                                            src="@/assets/dump.svg"
                                        ></v-img>
                                    </v-btn>

                                    <!-- # 고정핀  -->
                                    <v-btn
                                        v-if="index == 0"
                                        icon
                                        x-small
                                        depressed
                                        disabled
                                        style="position:absolute; bottom:4px; left:4px; z-index:3;"
                                        @click.stop=""
                                    >
                                        <v-img
                                            width="20"
                                            height="20"
                                            contain
                                            src="@/assets/fix.svg"
                                        ></v-img>
                                    </v-btn>
                                </div>

                                <!-- # 통화 선택 -->
                                <div
                                    class="ml-3"
                                    style="width:80px; cursor:pointer;"
                                    @click.stop="dialog.exchange_select = true; selected_index = index; selected_type = 'change'"
                                >
                                    <div
                                        class="d-flex align-end"
                                        style="height:30px;"
                                    >
                                        <!-- 이미지 -->
                                        <v-sheet
                                            width="24"
                                            height="24"
                                        >
                                            <!-- 통화 -->
                                            <v-img
                                                v-if="item.type == 'currency' && item.code"
                                                class="rounded-circle mx-auto"
                                                style="box-shadow:0px 0px 4px #ccc;"
                                                :src="require('@/assets/flags/'+item.code+'.svg')"
                                            ></v-img>

                                            <!-- 암호화폐 -->
                                            <v-img
                                                v-if="item.type == 'coin'"  
                                                class="rounded-circle"
                                                style="box-shadow:0px 0px 4px #ccc;"
                                                :src="require('@/assets/coins/'+item.code+'.png')"
                                            ></v-img>
                                        </v-sheet>

                                        <div
                                            class="ml-2"
                                            style="height:30px;"
                                        >
                                            <p
                                                style="font-family: 'Roboto', sans-serif !important; font-size:14px; color:#858d99;"
                                                class="text-center mb-0 mt-5px"
                                            >
                                                {{item.code}}
                                            </p>
                                            <v-icon
                                                style="margin-top:-9px;"
                                                size="20"
                                                color="#858d99"
                                                class="d-flex mx-auto"
                                            >
                                                mdi-chevron-down
                                            </v-icon>
                                        </div>
                                    </div>

                                    <!-- 한글명 -->
                                    <p
                                        class="alter_black--text mb-0 text-caption ml-1px text-truncate"
                                        style="height:18px; width:80px;"
                                    >
                                        {{$store.state.language == 'English' ? item.name_en : item.name_kr}}
                                    </p>
                                </div>

                                <v-spacer></v-spacer>

                                <!-- # 결과값 -->
                                <div
                                    style="height:48px;"
                                    :style="{ width: 'calc(100% - 130px)' }"
                                    class="mr-2px"
                                >
                                    <!-- # 메인 결과값 -->
                                    <div
                                        style="height:30px; width:100%;"
                                        class="mr-1 text-right mb-0 d-flex justify-end text-h6"
                                        :class="[
                                            {'grey--text': index != 0 && list.length == 0},
                                            $result_exchange_main(
                                                list, 
                                                $store.state.sub_tab != '000' ? (index == 0? $store.state.sub_tab : item.show_tab) : '000',
                                                index == 0 ? item.value : exchange_list[0].value,
                                                item.value
                                            ) == '유효하지 않은 값' || computed_result.main == 'Invalid value' ? 'font-weight-regular grey--text': 'font-weight-bold primary--text'
                                        ]"
                                    >
                                        <span v-if="
                                                $store.state.sub_tab != '000'
                                                && $store.state.summary_amount != 100
                                                && $result_exchange_main(
                                                    list, 
                                                    $store.state.sub_tab != '000' ? (index == 0? $store.state.sub_tab : item.show_tab) : '000',
                                                    index == 0 ? item.value : exchange_list[0].value,
                                                    item.value
                                                ) != '유효하지 않은 값'
                                                && $result_exchange_main(
                                                    list, 
                                                    $store.state.sub_tab != '000' ? (index == 0? $store.state.sub_tab : item.show_tab) : '000',
                                                    index == 0 ? item.value : exchange_list[0].value,
                                                    item.value
                                                ) != 'Invalid value'
                                                && $result_exchange_main(
                                                    list, 
                                                    $store.state.sub_tab != '000' ? (index == 0? $store.state.sub_tab : item.show_tab) : '000',
                                                    index == 0 ? item.value : exchange_list[0].value,
                                                    item.value
                                                ) != '0'
                                                && $result_exchange_main(
                                                    list, 
                                                    $store.state.sub_tab != '000' ? (index == 0? $store.state.sub_tab : item.show_tab) : '000',
                                                    index == 0 ? item.value : exchange_list[0].value,
                                                    item.value
                                                ) != null
                                            "
                                        >
                                            ≈
                                        </span>
                                        <span v-if="
                                                !(
                                                    (  
                                                        $result_exchange_main(
                                                            list, 
                                                            $store.state.sub_tab != '000' ? (index == 0? $store.state.sub_tab : item.show_tab) : '000',
                                                            index == 0 ? item.value : exchange_list[0].value,
                                                            item.value
                                                        )
                                                        == '유효하지 않은 값'
                                                        || $result_exchange_main(
                                                            list, 
                                                            $store.state.sub_tab != '000' ? (index == 0? $store.state.sub_tab : item.show_tab) : '000',
                                                            index == 0 ? item.value : exchange_list[0].value,
                                                            item.value
                                                        ) == 'Invalid value'
                                                    )
                                                    &&
                                                    index != 0
                                                )
                                            "
                                            class="text-truncate"
                                        >
                                            {{
                                                $pretty_operator(
                                                    $result_exchange_main(
                                                        list, 
                                                        $store.state.sub_tab != '000' ? (index == 0? $store.state.sub_tab : item.show_tab) : '000',
                                                        index == 0 ? item.value : exchange_list[0].value,
                                                        item.value
                                                    )
                                                )
                                            }}
                                        </span>

                                        <!-- # 없을때 -->
                                        <div
                                            :class="{ 'cursor-blink': blink_cursor && index == 0 && !item.is_expanded }"
                                            :style="{ display: !(blink_cursor && index == 0 && !item.is_expanded) ? 'none' : 'block' }"
                                            style="margin-left:-1px; width:1px; height:29px; background:#374151; margin-top:1px;"
                                        ></div>
                                    </div>

                                    <!-- # 서브 결과값 -->
                                    <template
                                        v-if="list.length != 0 || index == 0"
                                        class="text-truncate"
                                    >
                                        <p
                                            v-if="
                                                $result_exchange_main(
                                                    list, 
                                                    $store.state.sub_tab != '000' ? (index == 0? $store.state.sub_tab : item.show_tab) : '000',
                                                    index == 0 ? item.value : exchange_list[0].value,
                                                    item.value
                                                )
                                                != 
                                                $result_exchange_sub(
                                                    list,
                                                    $store.state.sub_tab === '000' ? item.show_tab : '000',
                                                    index == 0 ? item.value : exchange_list[0].value,
                                                    item.value
                                                )
                                            "
                                            class="mr-5px text-right primary--text text--lighten-1 mb-0 text-truncate"
                                            style="height:18px; font-size:16px; line-height:18px;"
                                        >
                                            <span v-if="
                                                    $store.state.sub_tab == '000'
                                                    && $store.state.summary_amount != 100
                                                    && $result_exchange_sub(
                                                        list,
                                                        $store.state.sub_tab === '000' ? item.show_tab : '000',
                                                        index == 0 ? item.value : exchange_list[0].value,
                                                        item.value
                                                    ) != '유효하지 않은 값'
                                                    && $result_exchange_sub(
                                                        list,
                                                        $store.state.sub_tab === '000' ? item.show_tab : '000',
                                                        index == 0 ? item.value : exchange_list[0].value,
                                                        item.value
                                                    ) != 'Invalid value'
                                                    && $result_exchange_sub(
                                                        list,
                                                        $store.state.sub_tab === '000' ? item.show_tab : '000',
                                                        index == 0 ? item.value : exchange_list[0].value,
                                                        item.value
                                                    ) != '0'
                                                    && $result_exchange_sub(
                                                        list,
                                                        $store.state.sub_tab === '000' ? item.show_tab : '000',
                                                        index == 0 ? item.value : exchange_list[0].value,
                                                        item.value
                                                    ) != null
                                                "
                                            >
                                                ≈
                                            </span>
                                            {{
                                                $pretty_operator(
                                                    $result_exchange_sub(
                                                        list,
                                                        $store.state.sub_tab === '000' ? item.show_tab : '000',
                                                        index == 0 ? item.value : exchange_list[0].value,
                                                        item.value
                                                    )
                                                )
                                            }}
                                        </p>
                                    </template>

                                    <!-- # 환율 정보 -->
                                    <template v-else>
                                        <!-- <p
                                            class="mr-1 text-right mb-0 force-ltr"
                                            style="height:18px; font-size:16px; line-height:18px; color:#858d99; font-weight:500;"
                                        >
                                            <span v-if="item.symbol">{{item.symbol}} {{item.value < 10 ? '100' : '1'}} = </span>
                                            {{exchange_list[0].symbol}}
                                            {{
                                                $toComma(
                                                    $math.divide(item.value, exchange_list[0].value) < 10 ?
                                                    ($math.divide(item.value, exchange_list[0].value) * 100).toFixed(2)
                                                    : $math.divide(item.value, exchange_list[0].value).toFixed(2)
                                                )
                                            }}
                                        </p> -->
                                        
                                        <p
                                            v-if="$store.state.language == '한국어'"
                                            class="mr-1 text-right mb-0 force-ltr"
                                            style="height:18px; font-size:16px; line-height:18px; color:#858d99; font-weight:500;"
                                        >
                                            <span v-if="item.symbol">
                                                {{ item.symbol }}
                                                {{ $calculate_multiplier(item.value, exchange_list[0].value).multiplier }} = 
                                            </span>
                                            {{ exchange_list[0].symbol }}
                                            {{
                                                $calculate_multiplier(item.value, exchange_list[0].value).formattedValue
                                            }}
                                        </p>
                                        <p
                                            v-if="$store.state.language == 'English' && item.type == 'currency'"
                                            class="mr-1 text-right mb-0 force-ltr"
                                            style="height:18px; font-size:16px; line-height:18px; color:#858d99; font-weight:500;"
                                        >
                                            <span v-if="item.symbol">
                                                {{ exchange_list[0].symbol }}
                                                {{ $calculate_multiplier(item.value, exchange_list[0].value).multiplier }} = 
                                            </span>
                                            {{ item.symbol }}
                                            {{
                                                $calculate_multiplier(item.value, exchange_list[0].value).formattedValue
                                            }}
                                        </p>
                                        <p
                                            v-if="$store.state.language == 'English' && item.type == 'coin'"
                                            class="mr-1 text-right mb-0 force-ltr"
                                            style="height:18px; font-size:16px; line-height:18px; color:#858d99; font-weight:500;"
                                        >
                                            <span v-if="item.symbol">
                                                {{ item.symbol }}
                                                1 =
                                            </span>
                                            {{ exchange_list[0].symbol }}
                                            {{
                                                $calculate_multiplier2(item.value, exchange_list[0].value).formattedValue
                                            }}
                                        </p>
                                    </template>
                                </div>

                                <!-- # 통화 기호 -->
                                <div
                                    class="align-self-end"
                                    style="width:26px !important; height:48px;"
                                >
                                    <div
                                        :class="[
                                            'mb-0 alter_black--text text--lighten-1 text-center text-truncate',
                                            item.symbol?.length >= 2 ? 'text-caption font-weight-medium' : 'text-h6'
                                        ]"
                                        style="height:30px;"
                                    >
                                        {{item.symbol}}
                                    </div>

                                    <!-- # 입력탭 (input_tab) -->
                                    <div
                                        v-if="!(index == 0 && $store.state.sub_tab != '000')"
                                        @click.stop="$toggle_show_tab(index, exchange_list)"
                                    >
                                        <div
                                            v-if="$store.state.language == '한국어'"
                                            class="d-flex align-end justify-center"
                                            style="height:18px; cursor:pointer;"
                                        >
                                            <!-- 모드 -->
                                            <div
                                                v-if="item.show_tab == '만억조'"
                                                class="d-flex rounded-15 pa-0"
                                                style="position:relative; width:26px !important; padding-left:2px !important;"
                                            >
                                                <v-btn
                                                    width="14"
                                                    height="14"
                                                    x-small
                                                    depressed
                                                    v-ripple="false"
                                                    style="padding:0; padding-bottom:1px; margin:1px; letter-spacing:1px !important; min-width:0px; z-index: 2;"
                                                    class="rounded-5 font-weight-bold"
                                                    :style="item.show_tab == '만억조'? 'color:white; background:#858d99;':'background:white; color:#667080;'"
                                                >   
                                                    <span v-if="item.show_tab == '만억조'" style="font-size:9px; font-weight:400; margin-left:1px;">만</span>
                                                </v-btn>
                                                <div
                                                    style="
                                                        z-index: 1;
                                                        background: #c3c6cb;
                                                        height: 4px;
                                                        width: 12px;
                                                        border-radius: 10px;
                                                        position: absolute;
                                                        left: 10px;
                                                        top: 6px;
                                                    "
                                                ></div>
                                            </div>

                                            <!-- 모드 -->
                                            <div
                                                v-if="item.show_tab == 'KMB'"
                                                class="d-flex rounded-15 pa-0 justify-end"
                                                style="position:relative; width:26px !important; padding-right:2px !important;"
                                            >
                                                <v-btn
                                                    width="14"
                                                    height="14"
                                                    x-small
                                                    depressed
                                                    v-ripple="false"
                                                    style="padding:0; padding-bottom:1px; margin:1px; letter-spacing:1px !important; min-width:0px; z-index: 2;"
                                                    class="rounded-5 font-weight-bold"
                                                    :style="item.show_tab == 'KMB'? 'color:white; background:#858d99;':'background:white; color:#667080;'"
                                                >   
                                                    <span v-if="item.show_tab == 'KMB'" style="font-size:9px; font-weight:400; margin-left:1px;">K</span>
                                                </v-btn>
                                                <div
                                                    style="
                                                        z-index: 1;
                                                        background: #c3c6cb;
                                                        height: 4px;
                                                        width: 12px;
                                                        border-radius: 10px;
                                                        position: absolute;
                                                        left: 4px;
                                                        top: 6px;
                                                    "
                                                ></div>
                                            </div>
                                        </div>

                                        <div
                                            v-if="$store.state.language == 'English'"
                                            class="d-flex align-end justify-center"
                                            style="height:18px; cursor:pointer;"
                                        >
                                            <!-- 모드 -->
                                            <div
                                                v-if="item.show_tab == 'KMB'"
                                                class="d-flex rounded-15 pa-0"
                                                style="position:relative; width:26px !important; padding-left:2px !important;"
                                            >
                                                <v-btn
                                                    width="14"
                                                    height="14"
                                                    x-small
                                                    depressed
                                                    v-ripple="false"
                                                    style="padding:0; padding-bottom:1px; margin:1px; letter-spacing:1px !important; min-width:0px; z-index: 2;"
                                                    class="rounded-5 font-weight-bold"
                                                    :style="item.show_tab == 'KMB'? 'color:white; background:#858d99;':'background:white; color:#667080;'"
                                                >   
                                                    <span v-if="item.show_tab == 'KMB'" style="font-size:9px; font-weight:400; margin-left:1px;">K</span>
                                                </v-btn>
                                                <div
                                                    style="
                                                        z-index: 1;
                                                        background: #c3c6cb;
                                                        height: 4px;
                                                        width: 12px;
                                                        border-radius: 10px;
                                                        position: absolute;
                                                        left: 10px;
                                                        top: 6px;
                                                    "
                                                ></div>
                                            </div>

                                            <!-- 모드 -->
                                            <div
                                                v-if="item.show_tab == '만억조'"
                                                class="d-flex rounded-15 pa-0 justify-end"
                                                style="position:relative; width:26px !important; padding-right:2px !important;"
                                            >
                                                <v-btn
                                                    width="14"
                                                    height="14"
                                                    x-small
                                                    depressed
                                                    v-ripple="false"
                                                    style="padding:0; padding-bottom:1px; margin:1px; letter-spacing:1px !important; min-width:0px; z-index: 2;"
                                                    class="rounded-5 font-weight-bold"
                                                    :style="item.show_tab == '만억조'? 'color:white; background:#858d99;':'background:white; color:#667080;'"
                                                >   
                                                    <span v-if="item.show_tab == '만억조'" style="font-size:9px; font-weight:400; margin-left:1px;">만</span>
                                                </v-btn>
                                                <div
                                                    style="
                                                        z-index: 1;
                                                        background: #c3c6cb;
                                                        height: 4px;
                                                        width: 12px;
                                                        border-radius: 10px;
                                                        position: absolute;
                                                        left: 4px;
                                                        top: 6px;
                                                    "
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- # 스왑 -->
                            <v-btn
                                v-if="index == 0 && exchange_list.length == 2 && !drag"
                                class="pa-1"
                                fab
                                x-small
                                depressed
                                color="white"
                                style="box-shadow:0px 2px 6px 4px #eee; position:absolute; left:50%; transform:translateX(-50%); bottom:-20px; z-index:3;"
                                @click="$swap_currency(exchange_list)"
                            >
                                <v-img
                                    width="24"
                                    height="24"
                                    contain
                                    src="@/assets/switch.svg"
                                ></v-img>
                            </v-btn>
                        </v-sheet>
                    </draggable>

                    <!-- # 통화 추가 -->
                    <v-sheet
                        class="rounded-12 d-flex align-center justify-center py-1 mt-3 px-4 mx-1"
                        style="box-shadow:0px 0px 4px 2px #e4e4e4; cursor:pointer;"
                        @click="dialog.exchange_select = true; selected_type = 'add'"
                    >
                        <v-icon color="primary">mdi-plus</v-icon>
                    </v-sheet>
                </div>
            </div>
        </div>

        <!-- {{list}} -->
        <!-- {{list}}

        {{computed_result}} -->

        <!-- {{exchange_list}} -->

        <!-- # 환전 선택 Dialog -->
        <v-dialog
            v-if="exchange_list.length != 0"
            v-model="dialog.exchange_select"
            width="auto"
            content-class="rounded-10 overflow-hidden"
            style="z-index:90;"
        >
            <v-sheet
                class="rounded-10"
            >
                <ExchangeListAdd
                    :dialog_type="selected_type"
                    :exchange_list="exchange_list"
                    :original_currency="exchange_list[0].value"
                    @selected="selected_change_handler"
                    @close="dialog.exchange_select = false"
                    @disable-keyboard-listener="disableKeyboardListener"
                    @enable-keyboard-listener="enableKeyboardListener"
                />
            </v-sheet>
        </v-dialog>

        <!-- # 키패드 -->
        <KeyPad
            v-if="!blink_cursor"
            style="position:absolute; bottom:0px;"
            :style="$vuetify.breakpoint.xsOnly? 'width:100vw':'width:440px'"
            class="mt-2"
            @keyPress="keyPress_Handler"
        />

        <KeyPad2
            v-if="blink_cursor"
            style="position:absolute; bottom:0px;"
            :style="$vuetify.breakpoint.xsOnly? 'width:100vw':'width:440px'"
            class="mt-2"
            @keyPress="keyPress_Handler"
        />
    </div>
</template>
<script>
import ExchangeListAdd from '@/components/ExchangeListAdd'
import KeyPad from '@/components/KeyPad'
import KeyPad2 from '@/components/KeyPad2'
import TestPad from '@/components/TestPad'
import HomeHeader from '@/layouts/header/HomeHeader'
import draggable from 'vuedraggable'

export default {
    components: {
        ExchangeListAdd,
        KeyPad,
        KeyPad2,
        TestPad,
        HomeHeader,
        draggable
    },

    props: {
        keyboardEvent: {
            type: String,
            default: null
        }
    },

    data: () => ({
        list: [],
        scales: {
            '만': 10000,
            '억': 100000000,
            '조': 1000000000000,
            '경': 10000000000000000,
            'K': 1000,
            'M': 1000000,
            'B': 1000000000,
            'T': 1000000000000
        },

        activeInput: null,
        activeType: "",
        activeIndex: 0,

        currencies: [],

        dialog: {
            exchange_select: false,
        },

        exchange_list: [],

        // 환전통화 선택 관련
        selected_index: null,
        selected_type: "",

        tabKeyHandler: null,
        drag: false,
    }),

    computed: {
        // 계산 > 결과값
        computed_result() {
            // edit 모드인 item이 있는지 체크
            const hasEditItem = this.list.some(item => item.edit)
            
            if (hasEditItem) {
                return {
                    main: '',
                    sub: ''
                }
            }

            return {
                main: this.$pretty_operator(this.$result_main(this.list, this.$store.state.sub_tab)),
                sub: this.$pretty_operator(this.$result_sub(this.list, this.$store.state.sub_tab, this.$store.state.input_tab))
            }
        },

        blink_cursor() {
            return this.list.length === 0 || this.list.every(item => !item.edit && !item.edit2)
        },

        computedMaxHeight() {
            let height
            height = this.$vuetify.breakpoint.height

            height = height - (this.$store.state.keypad_mode === 'expanded' ? 496 : 156)
            height = height - (this.$store.state.client_user.is_logined ? 0 : 29)

            if(this.$vuetify.breakpoint.height <= 880 && this.$store.state.keypad_mode == 'expanded') {
                let button_minus_height = (50 - Math.floor(this.$vuetify.breakpoint.height / 17.6)) * 5 + 10
                height = height + button_minus_height
            }

            return this.$store.state.twin? height/2 :height
        },

        computedMaxHeight2() {
            let height
            height = this.$vuetify.breakpoint.height

            height = height - (this.$store.state.keypad_mode === 'expanded' ? 496 : 156)
            height = height - (this.$store.state.client_user.is_logined ? 0 : 29)

            if(this.$vuetify.breakpoint.height <= 880 && this.$store.state.keypad_mode == 'expanded') {
                let button_minus_height = (50 - Math.floor(this.$vuetify.breakpoint.height / 17.6)) * 5 + 10
                height = height + button_minus_height
            }

            return this.$store.state.twin? height/2 - 8 : height - 8
        }
    },

    watch: {
        keyboardEvent(newVal) {
            console.log(newVal)
            if (newVal === 'disable') {
                document.removeEventListener('keydown', this.handle_pc_keyPress)
            } else if (newVal === 'enable') {
                document.addEventListener('keydown', this.handle_pc_keyPress)
            }
        },

        // store의 main_tab 상태만 감시
        '$store.state.main_tab': {
            handler(newValue, oldValue) {
                // 수정모드 모두 해제
                if(this.list.length > 0 && this.list.some(item => item.edit || item.edit2)){
                    this.$edit_end(this.list)
                    this.$edit_end2(this.list)
                }

                if (newValue === '환전' && newValue !== oldValue) {
                    this.$nextTick(() => {
                        if (this.list.length > 1) {
                            this.exchange_list[0].is_expanded = true
                        }
                    })
                }
            },
            immediate: true
        },

        // store의 sub_tab 상태만 감시
        '$store.state.sub_tab': {
            handler(newValue, oldValue) {
                // sub_tab이 만억조 또는 KMB일 때
                if (newValue === "만억조" || newValue === "KMB") {
                    this.exchange_list.forEach(item => {
                        // show_tab_touch가 true가 아닐 경우에만 변경
                        if (!item.show_tab_touch) {
                            item.show_tab = newValue
                        }
                    })
                }
            },
            immediate: true
        },

        // store의 sub_tab_by_header 상태만 감시
        '$store.state.sub_tab_by_header': {
            handler(newValue, oldValue) {
                if(this.list.length > 0){
                    this.$edit_end(this.list)
                    this.$edit_end2(this.list)
                }

                if(newValue == "만억조"){
                    this.$store.commit('update_sub_tab', '만억조')
                    this.$store.commit('update_input_tab', '만억조')
                }
                else if(newValue == "000"){
                    this.$store.commit('update_sub_tab', '000')
                    if(this.$store.state.language == '한국어'){
                        this.$store.commit('update_input_tab', '만억조')
                    }
                    else{
                        this.$store.commit('update_input_tab', 'KMB')
                    }
                }
                else if(newValue == "KMB"){
                    this.$store.commit('update_sub_tab', 'KMB')
                    this.$store.commit('update_input_tab', 'KMB')
                }
            },
            immediate: true
        },

        // store의 sub_tab 상태만 감시
        '$store.state.language': {
            handler(newValue, oldValue) {
                this.loadCurrencyList()
            },
            immediate: true
        },

        // # 계산식 변경 시
        list: {
            handler() {
                // 비활성 키 체크
                this.$checkDisableKey(this.list)

                this.$nextTick(() => {
                    if(this.$store.state.main_tab == "계산"){
                        const container = this.$el.querySelector('.scroll-container')
                        container.scrollTop = container.scrollHeight
                    }
                })
            },
            deep: true
        },

        // // # 환전 통화목록 변경 시
        // exchange_list: {
        //     handler(newValue, oldValue) {
        //         this.$nextTick(() => {
        //             const container = this.$el.querySelector('.scroll-container')
        //             console.log(newValue)

        //             // show_tab과 show_tab_touch가 변경된 경우 패스
        //             // const hasChangedVisibleItems = newValue.some((item, index) => {
        //             //     return (item.show_tab !== oldValue[index]?.show_tab) || (item.show_tab_touch !== oldValue[index]?.show_tab_touch)
        //             // })

        //             // show_tab과 show_tab_touch가 변경되지 않은 경우 스크롤 조정
        //             // if (!hasChangedVisibleItems) {
        //             //     container.scrollTop = container.scrollHeight
        //             // }

        //             // name_en이 변경되거나 추가되거나 삭제된 경우 스크롤 조정
        //             // const hasChangedNames = newValue.some((item, index) => {
        //             //     return item.name_en !== oldValue[index]?.name_en
        //             // })

        //             // 요소의 길이가 다를 경우 (추가 또는 삭제)
        //             const hasChangedLength = newValue.length !== oldValue.length

        //             // name_en이 변경되거나 요소의 길이가 다를 경우 스크롤 조정
        //             if (hasChangedLength) {
        //                 container.scrollTop = container.scrollHeight
        //             }
        //         })
        //     },
        //     deep: true
        // },
    },

    // # 마운트
    async mounted() {
        // Tab 키 이벤트
        // this.tabKeyHandler = (event) => this.$tab_key_to_update_tabs(event, this.list)

        // PC 키보드 이벤트
        document.addEventListener('keydown', this.handle_pc_keyPress)

        // 통화 목록 불러오기
        await this.loadCurrencyList()
    },

    // # 파괴 전
    beforeDestroy() {
        // Tab 키 이벤트 제거
        // document.removeEventListener('keydown', this.tabKeyHandler)

        // PC 키보드 이벤트 제거
        document.removeEventListener('keydown', this.handle_pc_keyPress)
    },

    // # 함수
    methods: {
        disableKeyboardListener() {
            document.removeEventListener('keydown', this.handle_pc_keyPress)
        },
        
        enableKeyboardListener() {
            document.addEventListener('keydown', this.handle_pc_keyPress)
        },

        // # 통화 목록 불러오기
        async loadCurrencyList() {
            await this.$http.post("/api/currency/select")
            .then(async(res) => {
                let krwData, usdData, currencyData

                // # 한국 원화 데이터 생성
                krwData = {
                    exchange_type: "현재 환율",
                    custom: 0,
                    type: "currency", 
                    chosung: "ㅎㄱ",
                    name_kr: "한국",
                    name_en: "South Korea",
                    code: "KRW",
                    symbol: "￦",
                    value: 1,
                    rate: 0,
                    is_expanded: false,
                    is_bookmarked: false,
                    show_tab: this.$store.state.language == "한국어" ? "만억조" : "KMB",
                    show_tab_touch: false
                }

                // # 미국 달러 데이터 생성
                usdData = res.data.find(item => item.code == "USD")
                usdData = {
                    ...usdData,
                    exchange_type: "현재 환율",
                    custom: 0,
                    type: "currency",
                    is_expanded: false,
                    is_bookmarked: false,
                    show_tab: this.$store.state.language == "한국어" ? "만억조" : "KMB",
                    show_tab_touch: false
                }


                // 통화 데이터에 기본값 추가
                currencyData = res.data.map(item => ({
                    ...item,
                    exchange_type: "현재 환율",
                    custom: 0,
                    type: "currency",
                    is_expanded: false,
                    is_bookmarked: false,
                    show_tab: this.$store.state.language == "한국어" ? "만억조" : "KMB",
                    show_tab_touch: false,
                }))

                // console.log(currencyData)


                // 코인 데이터 가져오기
                const coinRes = await this.$http.post("/api/coin/select")
                
                // 코인 데이터에 기본값 추가 
                const coinData = coinRes.data.map(item => ({
                    ...item,
                    exchange_type: "현재 환율", 
                    custom: 0,
                    type: "coin",
                    is_expanded: false,
                    is_bookmarked: false,
                    show_tab: this.$store.state.language == "한국어" ? "만억조" : "KMB",
                    show_tab_touch: false,
                }))

                // 한국 원화, 통화, 코인 데이터 병합

                // this.currencies.filter(item => item.code !== 'USD')

                if(this.$store.state.language == "한국어"){
                    // exchange_list에는 한국과 미국만 설정
                    this.currencies = [krwData, usdData, ...currencyData.filter(item => item.code != 'USD'), ...coinData]
                    this.exchange_list = [krwData, usdData]
                }
                else if(this.$store.state.language == "English"){
                    this.currencies = [usdData, krwData, ...currencyData.filter(item => item.code != 'USD'), ...coinData]
                    // exchange_list에는 한국과 미국만 설정
                    this.exchange_list = [usdData, krwData]
                }
            })
        },

        // # 환전 선택 핸들러
        selected_change_handler(item) {
            if(this.selected_type == "change"){
                this.$set(this.exchange_list, this.selected_index, {...item, show_tab: this.$store.state.sub_tab == "000"? "만억조": this.$store.state.sub_tab, show_tab_touch: false})
            }
            else if(this.selected_type == "add"){
                this.$set(this.exchange_list, this.exchange_list.length, {...item, show_tab: this.$store.state.sub_tab == "000"? "만억조": this.$store.state.sub_tab, show_tab_touch: false})

                // 하단으로 스크롤 이동
                this.$nextTick(() => {
                    const container = this.$el.querySelector('.scroll-container')
                    container.scrollTop = container.scrollHeight
                })
            }
            this.dialog.exchange_select = false
        },

        // # PC 키보드 이벤트
        handle_pc_keyPress(event) {
            let key

            if(event.code == "Tab"){
                console.log("Tab")
                this.$tab_key_to_update_tabs(event, this.list)
                return
            }

            if(event.code == "Backspace"){
                event.preventDefault() // 브라우저 기본 작동 (뒤로가기) 방지
                key = "←"
            }
            else if(event.code == "Enter" || event.code == "NumpadEnter"){ // NumpadEnter 추가
                key = "="
            }
            else if(event.code == "Escape"){
                key = "AC"
            }
            else if(event.code == "KeyR"){
                key = "경"
            }
            else if(event.code == "KeyE"){
                key = "조"
            }
            else if(event.code == "KeyW"){
                key = "억"
            }
            else if(event.code == "KeyQ"){
                key = "만"
            }
            else if(event.code == "KeyT"){
                key = "T"
            }
            else if(event.code == "KeyB"){
                key = "B"
            }
            else if(event.code == "KeyM"){
                key = "M"
            }
            else if(event.code == "KeyK"){
                key = "K"
            }
            else{
                key = event.key
            }

            // # 숫자 수정모드 (기능키)
            if(this.list.some(item => item.edit)){
                console.log("숫자 수정모드 (기능키)")
                event.preventDefault()
                this.editNumberMode_KeyHandler_OnlyFunction(key)
                return
            }

            // # 연산자 수정모드 (기능키)
            else if(this.list.some(item => item.edit2)){
                console.log("연산자 수정모드 (기능키)")
                this.editOperatorMode_KeyHandler_OnlyFunction(this.list, key)
                return
            }

            // # 기본모드
            else{
                console.log("기본모드")
                this.$defaultMode_KeyHandler(this.list, this.exchange_list, this.scales, key)
            }
        },

        // # 연산자 수정모드 종료 시 유효성 검사
        check_operator_edit_mode_end(){
            const isEditing = this.list.some(item => item.edit2)
            if (!isEditing) {
                // 수정모드로 VUEX 업데이트
                this.$store.commit('update_edit_mode2', false)
                return
            }

            if(this.activeIndex == null){
                return
            }

            if(this.list[this.activeIndex].edit_operator == null || this.list[this.activeIndex].edit_operator == ""){
                this.list.splice(this.activeIndex, 1)
                this.$edit_end2(this.list)
                this.$store.commit('update_edit_mode2', false)
                this.activeIndex = null
                return
            }

            // 계산식이 있고 연산자 수정모드이면 연산자 유효성 검사
            else if (this.list.length != 0 && this.list.some(item => item.edit2)) {
                const operator = this.$unpretty_operator(this.list[this.activeIndex].edit_operator)
                // console.log(operator)

                this.$edit_end2(this.list)

                // 유효성 검사
                // if (this.isValidOperator(operator)) {
                //     console.log("유효한 연산자입니다.")
                //     this.$edit_end2(this.list)
                // } else {
                //     console.log("유효하지 않은 연산자입니다.")
                //     // 유효하지 않은 경우 입력란에 포커스 설정
                //     this.focusStart('operator' + this.activeIndex);
                // }
            }
        },

        // # 연산자 수정모드 입력란에 키보드 허용 키
        handleKeydown_operator(event) {
            const allowedKeys = [
                '(', ')', '/', '*', '-', '+'
            ]

            if (!allowedKeys.includes(event.key)) {
                event.preventDefault()
                return
            }

            // 기본 이벤트를 막고 pretty_operator를 통해 변환된 값을 직접 입력
            event.preventDefault()
            
            const item = this.list.find(item => item.edit2)
            const index = this.list.findIndex(item => item.edit2)
            
            const input = this.$refs['operator' + index][0].$el.querySelector('input')
            const start = input.selectionStart
            const end = input.selectionEnd
            
            // 현재 값에서 커서 위치에 변환된 연산자를 삽입
            const prettyOperator = this.$pretty_operator(event.key)
            item.edit_operator = 
                item.edit_operator.slice(0, start) +
                prettyOperator +
                item.edit_operator.slice(end)

            // 커서 위치 조정
            this.$nextTick(() => {
                input.setSelectionRange(start + prettyOperator.length, start + prettyOperator.length)
            })
        },

        // # 연산자 수정 시
        handleInput(item, index) {
            this.$checkDisableKey(this.list)
            if (item.edit_operator === '') {
                this.focusStart('operator' + index)
            }
        },

        // # 입력란 Focus
        async focusStart(refName) {
            setTimeout(() => {
                if (this.$refs[refName] && this.$refs[refName][0]) {
                    this.$refs[refName][0].$el.querySelector('input').focus()
                }
            }, 0)
		},

        // # 활성화중인 입력란 저장
        setActiveInput(refName, type, index) {
            this.activeInput = this.$refs[refName][0].$el.querySelector('input')
            this.activeType = type
            this.activeIndex = index
        },

        // # 커서 위치에 입력
        insertAtCursor(key) {
            const input = this.activeInput
            const type = this.activeType
            const index = this.activeIndex

            const start = input.selectionStart
            const end = input.selectionEnd

            if(type == "operator"){
                if(key == "←"){
                    this.list[index].edit_operator = 
                    this.list[index].edit_operator.slice(0, start - 1)
                    + this.list[index].edit_operator.slice(end)

                    // 커서 재설정
                    setTimeout(() => {
                        input.focus()
                        input.setSelectionRange(start - 1, start - 1)
                    }, 0)
                    return
                }else{
                    if(this.list[index].edit_operator == null){
                        this.list[index].edit_operator = this.$pretty_operator2(key)
                    }
                    else{
                        this.list[index].edit_operator
                        = 
                        this.list[index].edit_operator.slice(0, start)
                        +
                        this.$pretty_operator2(key)
                        +
                        this.list[index].edit_operator.slice(end)
                    }
                }
            }
            else if(type == "number"){
                if(key == "←"){
                    this.list[index].edit_number = 
                    this.list[index].edit_number.slice(0, start - 1)
                    + this.list[index].edit_number.slice(end)


                    // 커서 재설정
                    setTimeout(() => {
                        input.focus()
                        input.setSelectionRange(start - 1, start - 1)
                    }, 0)
                    return
                }

                else{
                    this.list[index].edit_number
                    = 
                    this.list[index].edit_number.slice(0, start)
                    +
                    key
                    +
                    this.list[index].edit_number.slice(end)
                }
            }

            // 커서 재설정
            setTimeout(() => {
                input.focus()
                input.setSelectionRange(start + 1, start + 1)
            }, 0)
        },

        // # 키 입력
        keyPress_Handler(key) {
            console.log(key)

            // # 숫자 수정모드
            if(this.list.some(item => item.edit)){
                console.log("숫자 수정모드")
                this.editNumberMode_KeyHandler(key)
                return
            }

            // # 연산자 수정모드
            else if(this.list.some(item => item.edit2)){
                console.log("연산자 수정모드")
                this.editOperatorMode_KeyHandler(key)
                return
            }

            // # 기본모드
            else{
                console.log("기본모드")
                this.$defaultMode_KeyHandler(this.list, this.exchange_list, this.scales, key)
            }
        },

        // # 숫자 수정모드 (PC 키보드)
        editNumberMode_KeyHandler_OnlyFunction(key){
            // Tab 키
            if(key == "Tab"){
                let event = {
                    key: "Tab"
                }
                
                this.$tab_key_to_update_tabs(event, this.list)
                return
            }

            let editItem = this.list.find(item => item.edit)
            let editIndex = this.list.findIndex(item => item.edit)

            // 바로 전 아이템과 인덱스 찾기
            let beforeIndex = editIndex === 0 ? this.list.length - 1 : editIndex - 1
            let beforeItem = this.list[beforeIndex]

            let isLastItem = this.list.indexOf(editItem) === this.list.length - 1
            let isFirstItem = this.list.indexOf(editItem) === 0

            if(this.$keyType(key) == '기능키' || this.$keyType(key) == '연산자키'){
                // # 초기화
                if (key == "AC") {
                    if(this.list.length == 1){
                        this.list = []
                        this.$store.commit('reset_result')
                        this.$store.commit('update_edit_mode', false)
                    }else{
                        // # 괄호 처리
                        if(editItem.operator && editItem.operator.includes('(')){
                            // ')'가 처음으로 나타나는 아이템 찾기
                            const closingIndex = this.list.findIndex((item, index) => 
                                index > editIndex && item.operator.includes(')')
                            )

                            // ')'가 발견된 경우
                            if(closingIndex !== -1){
                                let temp_list = this.list

                                // ')' 제거
                                const closingItem = temp_list[closingIndex]
                                if(closingItem) {
                                    closingItem.operator = closingItem.operator.replace(')', '')

                                    // editItem부터 ')'가 포함된 아이템 이전까지 삭제
                                    temp_list.splice(editIndex, closingIndex - editIndex)

                                    this.list = temp_list
                                }
                            }

                            this.$edit_end(this.list)
                            this.$edit_start(this.list, beforeItem, beforeIndex)
                            this.focusStart('number'+beforeIndex)
                            editItem.edit = false
                            return
                        }

                        // 마지막 요소가 아니면 연산자도 같이 삭제 (-가 아닌 경우)
                        else if(!isLastItem  && !this.list[editIndex + 1].operator.startsWith('-')){
                            this.list[editIndex+1].operator = null
                            this.list.splice(editIndex, 1)
                            this.$store.commit('update_edit_mode', false)
                            this.$edit_end(this.list)
                        }

                        // 마지막 요소가 아니면 연산자도 같이 삭제 (-인 경우)
                        else if(!isLastItem  && !this.list[editIndex + 1].operator.startsWith('-')){
                            this.list.splice(editIndex, 1)
                            this.$store.commit('update_edit_mode', false)
                            this.$edit_end(this.list)
                        }

                        // 마지막 요소일 경우
                        else{
                            editItem.number = null
                            editItem.scale = null
                            editItem.edit_number = null
                            this.$edit_end(this.list)
                        }
                    }
                }

                // # 마지막 지우기
                else if (key == "←") {
                    // # 빈값 종료시
                    if(editItem.edit_number == null || editItem.edit_number == ""){

                        // # 괄호 처리
                        if(editItem.operator && editItem.operator.includes('(')){
                            // ')'가 처음으로 나타나는 아이템 찾기
                            const closingIndex = this.list.findIndex((item, index) => 
                                index > editIndex && item.operator.includes(')')
                            )

                            // ')'가 발견된 경우
                            if(closingIndex !== -1){
                                let temp_list = this.list

                                // ')' 제거
                                const closingItem = temp_list[closingIndex]
                                if(closingItem) {
                                    closingItem.operator = closingItem.operator.replace(')', '')

                                    // editItem부터 ')'가 포함된 아이템 이전까지 삭제
                                    temp_list.splice(editIndex, closingIndex - editIndex)

                                    this.list = temp_list
                                }
                            }

                            this.$edit_end(this.list)
                            this.$edit_start(this.list, beforeItem, beforeIndex)
                            this.focusStart('number'+beforeIndex)
                            editItem.edit = false
                            return
                        }

                        else{
                            // 첫 아이템이 아닐 경우
                            if(editIndex > 0){
                                this.list.splice(editIndex, 1)
                                this.$edit_end(this.list)
                                this.$edit_start(this.list, beforeItem, beforeIndex)
                                this.focusStart('number'+beforeIndex)
                                return
                            }

                            // 처음인데 계산식은 2이상인 경우 (-가 아닌 경우)
                            else if(editIndex == 0 && this.list.length > 1 && !this.list[editIndex + 1].operator.startsWith('-')){
                                this.list[editIndex + 1].operator = null
                                this.list.splice(editIndex, 1)
                                this.$store.commit('update_edit_mode', false)
                                return
                            }

                            // 처음인데 계산식은 2이상인 경우 (-인 경우)
                            else if(editIndex == 0 && this.list.length > 1){
                                this.list.splice(editIndex, 1)
                                this.$store.commit('update_edit_mode', false)
                                return
                            }

                            // 처음이면서 계산식도 1인 경우
                            else{
                                this.list = []
                                this.$store.commit('reset_result')
                                this.$store.commit('update_edit_mode', false)
                                return
                            }
                        }
                    }


                    if (editItem.edit_number) {
                        this.insertAtCursor(key)

                        // 마지막 <- 키로 숫자칸이 빈칸이 되면서 계산식도 더이상 없으면 계산식 비우고 일반모드 전환
                        if(this.list.length == 1 && (editItem.edit_number == null || editItem.edit_number == "")){
                            this.list = []
                            this.$store.commit('update_edit_mode', false)
                        }
                        return
                    }else{
                        this.list.splice(editIndex, 1)
                        this.$edit_end(this.list)
                        this.$edit_start(this.list, beforeItem, beforeIndex)
                        this.focusStart('number'+beforeIndex)
                        return
                    }
                }
            }

            else if(this.$keyType(key) == '단위키'){
                // this.editNumberMode_KeyHandler(key)
                this.insertAtCursor(key)
            }

            else if(this.$keyType(key) == '숫자키'){
                this.insertAtCursor(key)
            }
        },

        // # 연산자 수정모드 (기능키)
        editOperatorMode_KeyHandler_OnlyFunction(list, key){
            let editItem = list.find(item => item.edit2)
            let editIndex = list.findIndex(item => item.edit2)
            let beforeIndex = editIndex === 0 ? list.length - 1 : editIndex - 1
            let beforeItem = list[beforeIndex]

            // Tab 키
            if(key == "Tab"){
                // Tab 키
                if(key == "Tab"){
                    let event = {
                        key: "Tab"
                    }
                    
                    this.$tab_key_to_update_tabs(event, this.list)
                    return
                }
            }

            // AC 키
            else if (key == "AC") {
                if(list.length == 1){
                    list.splice(0)
                    this.$store.commit('reset_result')
                    this.$edit_end2(list)
                }else{
                    editItem.operator = null
                    editItem.edit_operator = null

                    if (editItem.edit_operator) {
                        editItem.operator = null
                        editItem.edit_operator = null
                    } else {
                        list.splice(editIndex, 1)
                        this.$edit_end2(list)
                        this.$edit_start(list, beforeItem, beforeIndex)
                        this.focusStart('number'+beforeIndex)
                    }
                }
            }

            // ← 키
            else if (key == "←") {
                if (editItem.edit_operator) {
                    this.insertAtCursor(key)
                } else {
                    list.splice(editIndex, 1)
                    this.$edit_end2(list)
                    this.$edit_start(list, beforeItem, beforeIndex)
                    this.focusStart('number'+beforeIndex)
                }
            }
        },
        
        // # 숫자 수정모드
        async editNumberMode_KeyHandler(key){
            let editItem = this.list.find(item => item.edit)
            let editIndex = this.list.findIndex(item => item.edit)
            let beforeIndex = editIndex === 0 ? this.list.length - 1 : editIndex - 1
            let beforeItem = this.list[beforeIndex]
            let isLastItem = this.list.indexOf(editItem) === this.list.length - 1

            // Tab 키
            if(key == "Tab"){
                // Tab 키
                if(key == "Tab"){
                    let event = {
                        key: "Tab"
                    }
                    
                    this.$tab_key_to_update_tabs(event, this.list)
                    return
                }
            }

            // AC 키
            else if (key == "AC") {
                if(this.list.length == 1){
                    this.list = []
                    this.$store.commit('reset_result')
                    this.$edit_end2(this.list)
                }else{
                    // # 괄호 처리
                    if(editItem.operator && editItem.operator.includes('(')){
                        // ')'가 처음으로 나타나는 아이템 찾기
                        const closingIndex = this.list.findIndex((item, index) => 
                            index > editIndex && item.operator.includes(')')
                        )

                        // ')'가 발견된 경우
                        if(closingIndex !== -1){
                            let temp_list = this.list

                            // ')' 제거
                            const closingItem = temp_list[closingIndex]
                            if(closingItem) {
                                closingItem.operator = closingItem.operator.replace(')', '')

                                // editItem부터 ')'가 포함된 아이템 이전까지 삭제
                                temp_list.splice(editIndex, closingIndex - editIndex)

                                this.list = temp_list
                            }
                        }

                        this.$edit_end(this.list)
                        this.$edit_start(this.list, beforeItem, beforeIndex)
                        this.focusStart('number'+beforeIndex)
                        editItem.edit = false
                        return
                    }

                    // 마지막 요소가 아니면 연산자도 같이 삭제 (-가 아닌 경우)
                    else if(!isLastItem  && !this.list[editIndex + 1].operator.startsWith('-')){
                        this.list[editIndex+1].operator = null
                        this.list.splice(editIndex, 1)
                        this.$store.commit('update_edit_mode', false)
                        this.$edit_end(this.list)
                    }

                    // 마지막 요소가 아니면 연산자도 같이 삭제 (-인 경우)
                    else if(!isLastItem  && !this.list[editIndex + 1].operator.startsWith('-')){
                        this.list.splice(editIndex, 1)
                        this.$store.commit('update_edit_mode', false)
                        this.$edit_end(this.list)
                    }

                    // 마지막 요소일 경우
                    else{
                        editItem.number = null
                        editItem.scale = null
                        editItem.edit_number = null
                        this.$edit_end(this.list)
                    }
                }
            }

            // ← 키
            else if (key == "←") {
                // # 빈값 종료시
                if(editItem.edit_number == null || editItem.edit_number == ""){

                    // # 괄호 처리
                    if(editItem.operator && editItem.operator.includes('(')){
                        // ')'가 처음으로 나타나는 아이템 찾기
                        const closingIndex = this.list.findIndex((item, index) => 
                            index > editIndex && item.operator.includes(')')
                        )

                        // ')'가 발견된 경우
                        if(closingIndex !== -1){
                            let temp_list = this.list

                            // ')' 제거
                            const closingItem = temp_list[closingIndex]
                            if(closingItem) {
                                closingItem.operator = closingItem.operator.replace(')', '')

                                // editItem부터 ')'가 포함된 아이템 이전까지 삭제
                                temp_list.splice(editIndex, closingIndex - editIndex)

                                this.list = temp_list
                            }
                        }

                        this.$edit_end(this.list)
                        this.$edit_start(this.list, beforeItem, beforeIndex)
                        this.focusStart('number'+beforeIndex)
                        editItem.edit = false
                        return
                    }

                    else{
                        // 첫 아이템이 아닐 경우
                        if(editIndex > 0){
                            this.list.splice(editIndex, 1)
                            this.$edit_end(this.list)
                            this.$edit_start(this.list, beforeItem, beforeIndex)
                            this.focusStart('number'+beforeIndex)
                            return
                        }

                        // 처음인데 계산식은 2이상인 경우 (-가 아닌 경우)
                        else if(editIndex == 0 && this.list.length > 1 && !this.list[editIndex + 1].operator.startsWith('-')){
                            this.list[editIndex + 1].operator = null
                            this.list.splice(editIndex, 1)
                            this.$store.commit('update_edit_mode', false)
                            return
                        }

                        // 처음인데 계산식은 2이상인 경우 (-인 경우)
                        else if(editIndex == 0 && this.list.length > 1){
                            this.list.splice(editIndex, 1)
                            this.$store.commit('update_edit_mode', false)
                            return
                        }

                        // 처음이면서 계산식도 1인 경우
                        else{
                            this.list = []
                            this.$store.commit('reset_result')
                            this.$store.commit('update_edit_mode', false)
                            return
                        }
                    }
                }


                if (editItem.edit_number) {
                    this.insertAtCursor(key)

                    // 마지막 <- 키로 숫자칸이 빈칸이 되면서 계산식도 더이상 없으면 계산식 비우고 일반모드 전환
                    if(this.list.length == 1 && (editItem.edit_number == null || editItem.edit_number == "")){
                        this.list = []
                        this.$store.commit('update_edit_mode', false)
                    }

                    return
                }else{
                    this.list.splice(editIndex, 1)
                    this.$edit_end(this.list)
                    this.$edit_start(this.list, beforeItem, beforeIndex)
                    this.focusStart('number'+beforeIndex)
                    return
                }
            }
            
            // 그 외 키
            else if(this.$keyType(key) == '숫자키' || this.$keyType(key) == '단위키'){
                this.insertAtCursor(key)
                return
            }

            await this.$edit_end(this.list)
        },

        // # 연산자 수정모드
        editOperatorMode_KeyHandler(key){
            if(key == "Tab"){
                // Tab 키
                if(key == "Tab"){
                    let event = {
                        key: "Tab"
                    }
                    
                    this.$tab_key_to_update_tabs(event, this.list)
                    return
                }
            }

            let editItem = this.list.find(item => item.edit2)
            let isLastItem = this.list.indexOf(editItem) === this.list.length - 1
            let isFirstItem = this.list.indexOf(editItem) === 0
            let editIndex = this.list.findIndex(item => item.edit2)
            let beforeIndex = editIndex === 0 ? this.list.length - 1 : editIndex - 1
            let beforeItem = this.list[beforeIndex]

            if(this.$keyType(key) == '기능키'){
                // # 초기화
                if (key == "AC") {
                    if(this.list.length == 1){
                        this.list = []
                        this.$store.commit('reset_result')
                        this.$edit_end2(this.list)
                    }else{
                        editItem.operator = null
                        editItem.edit_operator = null

                        if (editItem.edit_operator) {
                            console.log("HIHIHI")
                            // this.insertAtCursor(key)
                            editItem.operator = null
                        editItem.edit_operator = null
                        } else {
                            // console.log("HI")
                            // if(editIndex == this.list.length - 1){
                            //     console.log("HI")
                            //     this.list.splice(editIndex, 1)
                            //     this.$edit_end2(this.list)
                            // }

                            this.list.splice(editIndex, 1)
                            this.$edit_end2(this.list)
                            this.$edit_start(this.list, beforeItem, beforeIndex)
                            this.focusStart('number'+beforeIndex)
                        }
                    }
                }

                // # 마지막 지우기
                else if (key == "←") {
                    console.log("HI")
                    if (editItem.edit_operator) {
                        console.log("HIHIHI")
                        this.insertAtCursor(key)
                    } else {
                        // console.log("HI")
                        // if(editIndex == this.list.length - 1){
                        //     console.log("HI")
                        //     this.list.splice(editIndex, 1)
                        //     this.$edit_end2(this.list)
                        // }

                        this.list.splice(editIndex, 1)
                        this.$edit_end2(this.list)
                        this.$edit_start(this.list, beforeItem, beforeIndex)
                        this.focusStart('number'+beforeIndex)
                    }
                }
                // else{
                //     this.$globalSnackbar.show("수정모드에서는 이용할 수 없습니다.")
                // }
            }
            else if(this.$keyType(key) == '연산자키'){
                this.insertAtCursor(key)
            }

            else if(this.$keyType(key) == '숫자키' || this.$keyType(key) == '단위키'){
                this.$globalSnackbar.show(this.$store.state.language == 'English' ? "This is not available in the operator edit mode" : "연산자 수정모드에서는 이용할 수 없습니다.")
            }
        },

        // ! 드래그 시작 (검토 필요)
        onStart() {
            this.drag = true
            // this.isDragging = true
            this.exchange_list.forEach(item => {
                item.is_expanded = false
            })
        },

        // ! 드래그 종료 (검토 필요)
        onEnd() {
            this.drag = false
            // this.draggedElement = null
        },

        // (모바일 = PC에서 문제 발생)
        onChoose() {
            // this.drag = true
            // this.exchange_list.forEach(item => {
            //     item.is_expanded = false
            // })
        },

        // (모바일)
        onUnchoose() {
            // this.drag = false
            // 추가 동작이 필요하다면 여기에 작성
        },
    }
}
</script>
<style scoped>
::v-deep .v-btn::before {
    background-color: transparent !important;
}

.gradient-background {
	background: linear-gradient(to bottom, #f7edd5 0%, #FFFFFF 28%);
}

.border-right-solid {
    border-right: 1px solid #374151;
    margin-right: 3px !important;
}
</style>

<style scoped>
/* 깜빡임 애니메이션 정의 */
@keyframes blink {
    0%, 49% { opacity: 1; }
    50%, 100% { opacity: 0; }
}

.cursor-blink {
    animation: blink 1s step-end infinite;
}
</style>