<template>
    <div
        style="position:relative;"
        :style="$vuetify.breakpoint.xsOnly? 'width:100%;': ($store.state.screen_mode == 'expanded'? 'width:880px;':'width:440px;')"
        @click="$store.commit('update_little_menu', false)"
    >
        <div
            class="d-flex align-center justify-space-between primary"
            style="padding:8px 11px"
            :style="$vuetify.breakpoint.xsOnly? 'height:40px;':'height:48px;'"
        >
            <v-icon
                style="cursor:pointer;"
                color="white"
                size="20"
                @click.stop="$store.commit('update_little_menu', !$store.state.little_menu)"
            >
                mdi-dots-vertical
            </v-icon>

            <div
                v-if="!$vuetify.breakpoint.xsOnly && $store.state.screen_mode == 'expanded'"
                :style="$store.state.client_user.is_logined? 'width:155px;':'width:144px;'"
            ></div>

            <div
                v-if="$vuetify.breakpoint.xsOnly"
                style="width:80px;"
            ></div>

            <v-spacer></v-spacer>

            <v-sheet
                @click="reload()"
                style="cursor:pointer;"
                :width="$vuetify.breakpoint.xsOnly? '78px':'94px'"
                class="primary"
            >
                <v-img
                    contain
                    src="@/assets/logo/white_text.png"
                ></v-img>
            </v-sheet>

            <v-spacer></v-spacer>

            <div
                v-if="!$vuetify.breakpoint.xsOnly && $store.state.screen_mode == 'expanded'"
                class="d-flex align-center mobile"
                :style="$store.state.client_user.is_logined? 'width:155px;':'width:144px;'"
            >
                <p
                    v-if="!$store.state.client_user.is_logined"
                    class="white--text text-caption text-decoration-underline mb-0"
                    style="cursor:pointer;"
                    @click="$router.push('/auth/login')"
                >
                    {{ $store.state.language == 'English' ? 'Log In' : '로그인' }}
                </p>
                <p
                    v-if="$store.state.client_user.is_logined"
                    class="white--text text-caption text-decoration-underline mb-0"
                    style="cursor:pointer;"
                    @click="$router.push('/auth/logout')"
                >
                    {{ $store.state.language == 'English' ? 'Log out' : '로그아웃' }}
                </p>

                <v-select
                    outlined
                    dense
                    hide-details
                    class="rounded-15 shrink ml-3"
                    style="width:84px; height:20px; background:white;"
                    :items="language_list"
                    v-model="language"
                    @change="$store.commit('update_language', language)"
                >
                    <template v-slot:selection="{ item }">
                        <span style="font-family: 'Roboto', sans-serif !important; font-size:10px; padding-top:2px;">{{item.code}}</span>
                        <span class="pl-1" style="font-size:10px; font-weight:400;">{{item.text}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon small class="pt-7px ml-0 mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>
            </div>

            <div
                v-if="$vuetify.breakpoint.xsOnly"
                class="d-flex align-center mobile"
                style="width:80px;"
            >
                <v-select
                    outlined
                    dense
                    hide-details
                    class="rounded-15 shrink"
                    style="width:72px; height:20px; background:white;"
                    :items="language_list"
                    v-model="language"
                    @change="$store.commit('update_language', language)"
                >
                    <template v-slot:selection="{ item }">
                        <span style="font-size:10px; font-weight:400; padding-left:2px;">{{item.text}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon small class="pt-8px ml-0 mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>
            </div>

            <v-icon
                @click="$store.state.client_user.user_id? $router.push('/mypage'): $router.push('/auth/login')"
                style="cursor:pointer;"
                color="white"
                size="20"
            >
                mdi-cog-outline
            </v-icon>
        </div>

        <v-sheet
            v-if="$store.state.little_menu"
            style="position:absolute; top:48px; left:0px; background:white !important; height:52px; z-index:5;"
            :style="$store.state.language == 'English' ? 'width:180px;':'width:170px;'"
            class="pt-2 pl-3 rounded-b-5 d-flex align-center"
            outlined
            @click.stop=""
        >
            <p class="black_alter--text">
                <v-icon size="16" class="pb-2px mr-1" @click="$store.state.mypage_tab == '계산 설정'; $router.push('/mypage')">mdi-cog-outline</v-icon>
                {{ $store.state.language == 'English' ? 'Summary' : '쉽게 읽기' }}
            </p>

            <v-switch
                class="ml-4 mt-0 mb-4 custom-switch"
                hide-details
                dense
                v-model="summary_amount"
                @change="$store.commit('update_summary_amount', summary_amount? old_summary_amount : 100)"
            ></v-switch>
            <!-- {{ old_summary_amount }}
            {{ $store.state.summary_amount }} -->
        </v-sheet>
    </div>
</template>
<script>
export default {
    data: () => ({
        language_list: [
            { code: "KO", text: "한국어",},
            { code: "EN", text: "English"},
            // { code: "CN", text: "中文"},
            // { code: "JP", text: "日本語"},
        ],

        language: { code: "KO", text: "한국어"},
        menu: false,

        summary_amount: false,

        old_summary_amount: 1
    }),

    mounted(){
        this.language = this.$store.state.language

        if(this.$store.state.language == 'English'){
            this.$store.commit('update_sub_tab', 'KMB')
            this.$store.commit('update_input_tab', 'KMB')
            this.$store.commit('update_sub_tab_by_header', 'KMB')
        }

        this.old_summary_amount = this.$store.state.summary_amount == 100? 1 : 100
    },

    watch: {
        // store의 sub_tab 상태만 감시
        '$store.state.summary_amount': {
            handler(newValue, oldValue) {
                // console.log(newValue, oldValue)
                if(newValue == 100){
                    this.old_summary_amount = oldValue
                }

                this.summary_amount = newValue == 100? false : true
            },
            immediate: true
        }
    },


    methods: {
        reload(){
            window.location.href = '/'
        }
    }
}
</script>
<style scoped>
/* v-text-field, v-select, v-autocomplete > 전체 높이 지정 */
::v-deep .v-text-field .v-input__slot { 
    height: 20px !important;
}

/* mobile 테두리 실질 */ 
::v-deep .mobile .v-text-field .v-input__control .v-input__slot {
    min-height: 0 !important;
    padding: 0 0 0 10px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    align-items: center !important;
}

/* 화살표 */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 5px !important;
    margin-left: -28px !important;
    width:12px !important;
}

::v-deep .mobile .v-text-field input {
    margin-left:8px !important;
    margin-right:0px !important;
    padding-right:0px !important;
    font-size: 12px !important;
}

::v-deep .mobile .v-select__slot {
    margin-left:0px !important;
    margin-right:0px !important;
    padding-right:0px !important;
    font-size: 12px !important;
}

/* v-text-field, v-select, v-autocomplete > ▼ 아이콘 (v-text-field 한정 append-icon) */
::v-deep .v-text-field .v-input__icon--append .v-icon {
    width:12px !important;
}
</style>